import React, { Fragment, useEffect } from 'react';
import Navbar from "../../components/navbar";
import classStyles from './styles';
import BottomBar from "./components/bottomBar";
import FindMail from "./components/findMail"
import Controls from "./components/controls"
import Tags from "./components/tags"
import {useDispatch} from "react-redux"
import {MAILS_CLEAR_STORE} from "./store/actions"

const Login = () => {
  const classes = classStyles();

  const dispatch = useDispatch(); 

  useEffect(() => {
    return () => {dispatch({type: MAILS_CLEAR_STORE})}
  }, []);

  return (
    <Fragment>
      <Navbar/>
      <div className={classes.rootContainer}>
          <div className={classes.container}>
            <FindMail/>
            <Tags/>
            <Controls/>
        </div>  
      </div>  
      <BottomBar/>
    </Fragment>
    );
}

export default Login;