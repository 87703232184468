import React, { Fragment, useContext } from 'react';
import Blog from "../components/blog"
import Spacer from "../../spacer";
import Login from "../components/login"
import Register from "../components/register"
import {useSelector} from "react-redux"

export const Mobile = () => {
    const screen = useSelector(state => state.screen);
    const { screenWidth } = screen;

    return(
        screenWidth > 650 ? null 
        :
        <Fragment>
            <Blog/>
            <Spacer/>
            <Register/>
            <Login/>
        </Fragment>
    )
}

  export default Mobile;
