import React, { Fragment } from 'react';
import Blog from "../components/blog"
import OglasnaTabla from "../components/oglasna tabla"
import Spacer from "../../spacer";
import Login from "../components/login"
import Register from "../components/register"
import Logout from "../components/logout"
import {useSelector} from "react-redux"
import Role from "../components/role"
import Tags from "../components/tags"
import Mails from "../components/mails"

export const Computer = () => {
    const screen = useSelector(state => state.screen);
    const user = useSelector(state => state.user);
    const { screenWidth } = screen;
    const { token, role } = user;

    return(
        screenWidth <= 650 ? null 
        :
        <Fragment>
            <Blog/>
            <OglasnaTabla/>
            <Spacer/>
            {token===null ? 
                <Fragment>
                    <Register/>
                    <Login/>
                </Fragment>
                :
                <Fragment>
                    {role === "admin" ? <Mails/> : null}
                    {role === "admin" ? <Tags/> : null}
                    {role === "admin" ? <Role/> : null}
                    <Logout/>
                </Fragment>
            }
        </Fragment>
    )
}

  export default Computer;
