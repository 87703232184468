import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    spacer: {
        flexGrow: 1,
    },

});


export default classStyles;
