/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classStyles, { styles } from './styles';
import {useSelector, useDispatch} from "react-redux"
import APIGetTags from "../../API/APIGetTags";
import APIDeleteTag from "../../API/APIDeleteTag";
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TAGS_UPDATE_TAGS } from "../../store/actions";
import Dialog from "../../../../components/dialog";
import useIsMounted from "../../../../components/isMounted"

export default function ComboBox() {
    const classes = classStyles();
    const [tags, setTags] = useState([]);
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContet, setDialogContent] = useState("");
    const [button, disableButton] = useState(true);
    const [apiResult, setApiResult] = useState(null);
    const [tag, setTag] = useState("");
    const isMounted = useIsMounted();

    const dispatch = useDispatch(); 
    const state = useSelector(state => state.tags);
    const { updateTags } = state;

    useEffect(() => {
        if(apiResult!=null){
          const {status} = apiResult;
          if(status=="sucess"){
            setDialogTitle("Uspesno ste obrisali tag")
            setDialogContent("")
            setDialog(true);
            
            setTag("");
            
            APIGetTags() //update tagova u pretrazi kad se obrise neki tag iz liste
            .then(result => {
              if(isMounted())
                setTags(result)
            })
          }else{
            const {errno} = apiResult

            if(errno==1451){
              setDialogTitle("Tag nije obrisan")
              setDialogContent("Tag nije moguce obrisati zato sto postoje objave koje ga koriste.")
              setDialog(true);
            }else{
              setDialogTitle("Desila se greska")
              setDialogContent("Pokusajte ponovo.")
              setDialog(true);
            }
          }
        }
    
      }, [apiResult]);

    useEffect(() => { //update tagova u pretrazi kad se doda nov tag u komponenti addTag
        if(updateTags==true){
            APIGetTags()
            .then(result => {
              if(isMounted())
                setTags(result)
            })
            dispatch({type: TAGS_UPDATE_TAGS, payload: false});
        }
    }, [updateTags]);

    useEffect(() => { //inicijalno ucitavanje tagova
        APIGetTags()
        .then(result => {
          if(isMounted())
            setTags(result)
        })
    }, []);

    const submit = () => {
        disableButton(true)

        let DTO = {
          tag: tag
        }
  
        APIDeleteTag(DTO) 
        .then(result => {
          if(isMounted())
            setApiResult(result)
        })
    }

    const onChange = (e, value) => {
        if(value==null){
            disableButton(true)
        }else{
            disableButton(false)
        }

        setTag(value)
    }

  return (
    <Fragment>
        <Autocomplete
        value={tag}
        onChange={onChange}
        options={tags}
        getOptionLabel={(option) =>  option==="" ? "": option.tag}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Pronadji tag" variant="outlined" />}
        />
        <Button disabled={button} onClick={submit} className={classes.submit} style={styles.buttonColor(button)}>
            <Typography variant="button" color="inherit" className={classes.fontStyle}>Obrisi tag</Typography>
        </Button>
        <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
    </Fragment>
  );
}

