import React, {useEffect} from 'react';
import classStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CREATE_POST_SET_TYPE } from "../../store/actions";
import {useDispatch} from "react-redux"

const Type = () => {
  const classes = classStyles();
  const [role, setRole] = React.useState("posetioc");
  const dispatch = useDispatch(); 

  const handleChange = event => {
      const value = event.target.value
      setRole(value);
    };
    
  useEffect(()=> {
      dispatch({type: CREATE_POST_SET_TYPE, payload: role});
  },[role])

    return (
        <FormControl className={classes.formControl}>
          <InputLabel>Tip</InputLabel>
          <Select
            value={role}
            onChange={handleChange}
          >
            {/* <MenuItem value={10}>Pitanje</MenuItem>
            <MenuItem value={20}>Materijal</MenuItem> */}
            <MenuItem value={"posetioc"}>Posetilac</MenuItem>
            <MenuItem value={"clan"}>Clan</MenuItem>
          </Select>
      </FormControl>
  );
}

export default Type;