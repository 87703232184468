import React from 'react';
import classStyles from './styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const Post = (props) => {
  const classes = classStyles();
  const { images, removeImage } = props; 

  return (
    images.length === 0 ?
    null
    :
    <div className={classes.container}>
      <ImageList className={classes.imageList} cols={2} rowHeight={300}>
        {images.map((image, index) => (
          <ImageListItem key={index}>
            <img src={URL.createObjectURL(image)} alt={"image is loading"} />
            <ImageListItemBar
              classes={{
                root: classes.root,
              }}
              // actionIcon={
              //   <IconButton className={classes.iconButton} onClick={()=>removeImage(image)}>
              //     <DeleteIcon className={classes.icon}/>
              //   </IconButton>
              // }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

export default Post;