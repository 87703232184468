import React, { Fragment } from 'react';
import Navbar from "../../components/navbar";
import Spacer from "../../components/spacer"
import classStyles from './styles';
import BottomBar from "./components/bottomBar";
import DeleteTag from "./components/deleteTag"
import AddTag from "./components/addTag"

const Login = () => {
  const classes = classStyles();

  return (
    <Fragment>
      <Navbar/>
      <div className={classes.rootContainer}>
        <Spacer/>
        <div className={classes.contentContainer}>
          <AddTag/>
        </div>       
        <div className={classes.contentContainer}>
          <DeleteTag/>
        </div>  
        <Spacer/>
      </div>  
      <BottomBar/>
    </Fragment>
    );
}

export default Login;