import React, { Fragment } from 'react';
import Navbar from "../../components/navbar";
import Spacer from "../../components/spacer"

const Login = () => {

  return (
      <Fragment>
        <Navbar/>
      </Fragment>
    );
}

export default Login;