import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import classStyles from './styles';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import API from "../../API/API"
import Dialog from "../../../../components/dialog";
import { homeRoute } from "../../../../router/routes";
import { withRouter } from 'react-router-dom';
import { SET_USER } from "../../../../store/user/actions";
import {useDispatch} from "react-redux"
import useIsMounted from "../../../../components/isMounted"
import ReCAPTCHA from "react-google-recaptcha";

const Register = (props) => {
  const classes = classStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [dialog, setDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContet, setDialogContent] = useState("");
  const [apiResult, setApiResult] = useState(null);
  const [button, disableButton] = useState(false);
  const dispatch = useDispatch(); 
  const isMounted = useIsMounted();

  const [rechapthaNotVerified, setRechaptaNotVerified] = useState(true)

  const onClick = () => {

    if(password!==passwordConfirm){
      setDialogTitle("Sifre se razlikuju")
      setDialogContent("Pokusajte ponovo.")
      setDialog(true);
    }
    else{
      disableButton(true)

      let DTO = {
        username: username, 
        password: password
      }
   
      API(DTO)
      .then(result => {
        if(isMounted())
          setApiResult(result)
      })
    }
  }

  useEffect(() => {
    if(apiResult!=null){
    disableButton(false)
    const {status} = apiResult;

      if(status=="error"){
        const {dbResponse} = apiResult;
        const {code} = dbResponse;

        if(code=="ER_DUP_ENTRY"){
          setDialogTitle("Korisnicko ime vec postoji")
          setDialogContent("Pokusajte ponovo.")
          setDialog(true);
        }else{
          setDialogTitle("Desila se nepoznata greska")
          setDialogContent("Posaljite ovu gresku tehnickoj podrsci: "+code)
          setDialog(true);
        }
      }

      if(status=="sucess"){
        const {token, role} = apiResult;
        dispatch({type: SET_USER, payload: {token, role}});
        props.history.push(homeRoute);
      }
    }

  }, [apiResult]);

  function onChange(value) {
    setRechaptaNotVerified(false)
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.heading} >Registracija</Typography>
      <Paper className={classes.paper} elevation={0}>
      <TextField
          placeholder="Korisničko ime"
          autoComplete="username"
          InputProps={{
            className: classes.input
          }}
          fullWidth
          variant="outlined"
          onChange={(e)=>setUsername(e.target.value)}
      />
      <TextField
      placeholder="Šifra"
      type="password"
      InputProps={{
        className: classes.input
      }}
      fullWidth
      variant="outlined"
      onChange={(e)=>setPassword(e.target.value)}
      />
      <TextField
      placeholder="Potvrdi šifru"
      type="password"
      InputProps={{
        className: classes.input
      }}
      fullWidth
      variant="outlined"
      onChange={(e)=>setPasswordConfirm(e.target.value)}
      />
      <Button disabled={button || rechapthaNotVerified} fullWidth size="large" className={classes.button} onClick={onClick}>Registruj me</Button>
      </Paper>
      <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
      <ReCAPTCHA
        sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
        onChange={onChange}
        className={classes.recaptha}
      />
    </div>
  );
}

export default withRouter(Register);
