import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    container: {
        marginBottom: 50,
        // marginTop: 50,
    },

    text: {
        marginLeft: 10,
    }
});

export default classStyles;
