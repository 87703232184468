import React from 'react';
import classStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

export const Logout = (props) => {
    const classes = classStyles();

    return(
        <IconButton className={classes.color}>
        {/* <a href={process.env.PUBLIC_URL + "BlogTemplate/Blog/Blog.html"} className={classes.link}>  */}
        <a href="https://nutri4run.com/" className={classes.link}> 
            <Typography variant="subtitle2">Početna</Typography>
        </a>
        </IconButton>
    )
}

export default withRouter(Logout);

