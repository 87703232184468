import React, { Fragment, useEffect } from 'react';
import Navbar from "../../components/navbar";
import Spacer from "../../components/spacer"
import classStyles from './styles';
import BottomBar from "./components/bottomBar";
import User from "./components/user"
import Role from "./components/role"
import Controlls from "./components/controlls"
import {useDispatch} from "react-redux"
import {ROLES_CLEAR_USER} from "./store/actions"

const Login = () => {
  const classes = classStyles();
  const dispatch = useDispatch();     

  useEffect(() => {
    return () => {dispatch({type: ROLES_CLEAR_USER})}
  }, []);

  return (
    <Fragment>
      <Navbar/>
      <div className={classes.rootContainer}>
        <Spacer/>
        <div className={classes.contentContainer}>
          <User/>
          <Role/>
          <Controlls/>
        </div>        
        <Spacer/>
      </div>  
      <BottomBar/>
    </Fragment>
    );
}

export default Login;