import * as actions from "./actions";
import initialState from "./state"

const Reducer = (state=initialState, action) => {

    switch (action.type) {
        case actions.MAILS_SET_EMAIL:
            return {
                ...state,
                email: action.payload
            };
        case actions.MAILS_SET_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        case actions.MAILS_RELOAD_EMAILS:
            return {
                ...state,
                reloadEmails: action.payload
            };
        case actions.MAILS_CLEAR_STORE:
            return {
                ...initialState
            };
        default:
            return state;
    }
  };
  
  export default Reducer;