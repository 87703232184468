const Service= (url, DTO) =>{
  return fetch(url,{
    method: "POST",  
    body: DTO
    })
  .then((response)=>{
      return response.json()
  })

}

export default Service;