import React, {useState, useEffect} from 'react';
import classStyles from './styles';
import { withRouter } from 'react-router-dom';
import Spacer from "../../../../../components/spacer";
import Likes from "./components/likes"
import Date from "./components/date"
import User from "./components/user"
import Report from "./components/report"
import Delete from "./components/delete"
import Edit from "./components/edit"
import useIsMounted from "../../../../../components/isMounted"
import APICheckOwner from "../../../API/APICheckOwner"
import {useSelector} from "react-redux"

const Post = (props) => {
  const classes = classStyles();
  const { likes, postId, userId, data, date } = props;
  const [owner, setOwner] = useState(false);
  const user = useSelector(state => state.user);
  const { token } = user;
  const isMounted = useIsMounted();

  useEffect(() => { 
    APICheckOwner({userId: userId, username: token})
    .then(result => {
      if(isMounted()){
        setOwner(result)
      }
    })
  }, []);


  return (
    <div className={classes.container}>
      {/* <Likes likes={likes} postId={postId}/> */}
      <Date date={date}/>
      <Spacer/>
      {/* <User/> */}
      <Spacer/>
      {/* <Report/> */}
      {owner ? <Edit data={data} />:null}
      {owner ? <Delete postId={postId}/>:null}
    </div>
  );
}

export default withRouter(Post);