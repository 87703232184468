const Service= (url, DTO) =>{
  return fetch(url,{
    method: "POST", 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(DTO)
    })
  .then((response)=>{
      return response.json()
  })

}

export default Service;