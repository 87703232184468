import React, { Fragment, useState, useEffect } from 'react';
import classStyles from './styles';
import useIsMounted from "../../../../../components/isMounted"
import APIGetFile from "../../../API/APIGetFile"
import FileViewer from "../../../../../components/fileManager/fileViewer"

const Post = (props) => {
  const classes = classStyles();
  const {documents : docs} = props;
  const [documents, setDocuments] = useState([]);

  const isMounted = useIsMounted();
  
  useEffect(async() => {
    for (let i=0; i<docs.length;i++){
      let path = docs[i].split("/");
      let filename = path[path.length-1] 
      let ext = filename.split(".")[1];
      
      APIGetFile({filePath: docs[i]})
      .then(result => {
        if(isMounted){
          let file = new File([result], filename, {type:'.'+ext}); 
          //it will work without {type:'.'+ext}, because type will be 
          //calculated again later from path inside pdfReader and fileReader
          setDocuments(oldArray => [...oldArray, file])
        }
      })
    }

  }, [docs]);

  return (
    <Fragment>
      {documents.length > 0 ? <FileViewer files={documents}/> : null}
    </Fragment>
  );
}

export default Post;