import React, {useEffect, useState} from 'react';
import classStyles, { styles } from './styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import APIDeleteEmail from "../../API/APIDeleteEmail";
import APIInserOrUpdate from "../../API/APIInsertOrUpdate";
import Dialog from "../../../../components/dialog";
import { useDispatch, useSelector } from "react-redux"
import useIsMounted from "../../../../components/isMounted"
import { MAILS_RELOAD_EMAILS } from "../../store/actions";

export default function SimpleSelect() {
    const classes = classStyles();
    const [tag, setTag] = React.useState("");
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContet, setDialogContent] = useState("");
    const [deleteButton, disableDeleteButton] = useState(true);
    const [submitButton, disableSubmitButton] = useState(true);
    const dispatch = useDispatch(); 
    const isMounted = useIsMounted();

    const emailStore = useSelector(state => state.email);
    const { email, tags } = emailStore;

    useEffect(() => { 
      if(email.address.length==0){
        disableDeleteButton(true)
        disableSubmitButton(true)
      }else {
        if(email.id==-1)
          disableDeleteButton(true)
        else
          disableDeleteButton(false)

        disableSubmitButton(false)
      }

    }, [email]);

    const handleDelete = () => {
      disableDeleteButton(true)
      if(email.id!=-1){
        APIDeleteEmail({id: email.id})
        .then(result => {
          if(isMounted()){
            if(result==true){
              setDialogTitle("Uspeno ste obrisali mail")
              setDialogContent("")
              setDialog(true);
            }else{
              setDialogTitle("Desila se greska u toku brisanja")
              setDialogContent("")
              setDialog(true);
            }
            disableDeleteButton(false)
            dispatch({type: MAILS_RELOAD_EMAILS, payload: true});
          }
        })
      }
    };
  
    const handleInsertOrUpdate = () => {
      disableSubmitButton(true)

      if(email.address.length==0){
        setDialogTitle("Neispravan unos")
        setDialogContent("Niste uneli ispravnu email adresu.")
        setDialog(true);
        return;
      }

      APIInserOrUpdate({email: email, tags: tags})
      .then(result => {
        if(isMounted()){
          if(result==true){
            setDialogTitle("Uspeno ste uneli izmene")
            setDialogContent("")
            setDialog(true);
          }else{
            setDialogTitle("Desila se greska u toku izmene.")
            setDialogContent("")
            setDialog(true);
          }
          disableSubmitButton(false)
          dispatch({type: MAILS_RELOAD_EMAILS, payload: true});
        }
      })
    }

    return (
      <div className={classes.container}>
        <Button disabled={submitButton} onClick={handleInsertOrUpdate} style={styles.buttonColor(submitButton)}  className={classes.submit}>
            <Typography 
              variant="button" 
              color="inherit" 
              className={classes.fontStyle}
              >
              Unesi izmene
            </Typography>
        </Button>
        <Button disabled={deleteButton} onClick={handleDelete} style={styles.buttonColor(deleteButton)} className={classes.submit}>
            <Typography 
              variant="button" 
              color="inherit" 
              className={classes.fontStyle}
            >
              Obriši email
            </Typography>
        </Button>
        <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
      </div>
    );
}
