import React, { Fragment,useEffect } from 'react';
import Spacer from "../../components/spacer";
import Navbar from "../../components/navbar";
import classStyles from './styles';
import Title from "./components/title";
import Type from "./components/type";
import FormTitle from "./components/formTitle";
// import TextEditor from "./components/textEditor";
import Paper from '@material-ui/core/Paper';
import FileUploader from "./components/fileManager/fileUploader"
import Controlls from "./components/controlls";
import TextArea from "./components/textArea";
import ImageUploader from "./components/imageUploader"
import Tags from "./components/tags"
import {useDispatch} from "react-redux"
import {CREATE_POST_CLEAR_STORE} from "./store/actions"

const CreatePost = () => {
    const classes = classStyles();
    const dispatch = useDispatch(); 

    useEffect(() => {
      return () => {dispatch({type: CREATE_POST_CLEAR_STORE})}
    }, []);

    return (
    <Fragment>
    <Navbar/>
    <div className={classes.container}>
      <Spacer/>
      <div className={classes.contentContainer}>
        <FormTitle/>
        <Type/>
        <Paper className={classes.paper} elevation={0}>
          <Title/>
           {/* <TextEditor/> */}
          <TextArea/>
          <ImageUploader/>
          <FileUploader/>
          <Tags/>
          <Controlls/>
        </Paper>
      </div>        
      <Spacer/>
    </div>        
    </Fragment>
  );
}

export default CreatePost;