import * as actions from "./actions";
import initialState from "./state"

const Reducer = (state=initialState, action) => {

    switch (action.type) {
        case actions.ROLES_SET_USER:
            return {
                ...state,
                username: action.payload.username,
                role: action.payload.role
            };
        case actions.ROLES_CLEAR_USER:
        return {
            ...state,
            username: null,
            role: null
        };
        case actions.ROLES_SET_ROLE:
            return {
                ...state,
                role: action.payload
            };
        default:
            return state;
    }
  };
  
  export default Reducer;