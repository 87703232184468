import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    formControl: {
        marginBottom: 10,
    },

});

export default classStyles;
