import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import classStyles from './styles';
import Spacer from "../../../../../components/spacer";
import HelpIcon from '@material-ui/icons/Help';
import Book from '@material-ui/icons/MenuBook';

const Post = (props) => {
  const classes = classStyles();
  const {type, title} = props;

  return (
    <div className={classes.container}>
      {/* <div className={classes.container}> */}
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      {/* </div> */}
      <Spacer/>
      {/* { type === "q" ?
        <HelpIcon className={classes.postType} />
        :
        <Book className={classes.postType} />
      } */}
    </div>
  );
}

export default Post;