import React, {Fragment, useState, useEffect} from 'react';
import classStyles from './styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "./components/dialog"
import Dialog from "../../../../../../../components/dialog"
import useIsMounted from "../../../../../../../components/isMounted"
import APIDeletePost from "../../../../../API/APIDeletePost"
import { homeRoute } from "../../../../../../../router/routes";

const Post = (props) => {
  const classes = classStyles();

  const {postId} = props;
  const isMounted = useIsMounted();
  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContet, setDialogContent] = useState("");

  const [deleted, setDeleted] = useState(false);

  const onClick = () => {
    setDialogTitle("Brisanje objave")
    setDialogContent("Da li ste sigurni da želite da obrišete objavu?")
    setDeleteDialog(true);
  }

  useEffect(() => { 
    if(deleted && !dialog){
      props.history.push(homeRoute);
    }
  }, [dialog]);

  const deletePost = () => {
    setTimeout(function(){
      APIDeletePost({postId: postId})
      .then(result => {
        if(isMounted()){
          if(result==true){
            setDialogTitle("Brisanje objave")
            setDialogContent("Uspešno ste obrisali objavu.")
            setDialog(true);
            setDeleted(true)
          }
        }
      })
    }, 50);
  }

  return (
    <Fragment>
      <Button onClick={onClick}>
        <DeleteIcon className={classes.date}/>
      </Button>
      <DeleteDialog dialog={deleteDialog} setDialog={setDeleteDialog} deletePost={deletePost} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
      <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
    </Fragment>
  );
}

export default withRouter(Post);