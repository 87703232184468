import * as actions from "./actions";
import initialState from "./state"

const Reducer = (state=initialState, action) => {

    switch (action.type) {
        case actions.TAGS_UPDATE_TAGS:
            return {
                ...state,
                updateTags: action.payload
            };
        default:
            return state;
    }
  };
  
  export default Reducer;