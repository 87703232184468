import * as actions from "./actions";
import {initialState} from "./state"; 
 
const Reducer = (state=initialState, action) => {
    switch (action.type) {
        case actions.SCREEN_WIDTH:
            return {
                ...state,
                screenWidth: action.payload
            };
        case actions.SCREEN_HEIGHT:
            return {
                ...state,
                screenHeight: action.payload
            };    
        default:
            return state;
    }
};

export default Reducer;