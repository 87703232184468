const initialState = {
    error: null,
    reloadPosts: false,
    tags: [],
    search: "",
};

const state = (() => {
    
    return localStorage.getItem("home") !== null ? JSON.parse(localStorage.getItem("home")) : initialState
}
)()

export default state