import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    color: {
        color: "white",
        display:"flex", 
        textTransform: 'none',

    },

});

export default classStyles;
