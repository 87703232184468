import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import classStyles from './styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Account from "../../../../../../../assets/Account.jpg"
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';

const Post = (props) => {
  const classes = classStyles();

  return (
    <div className={classes.container}>
      <Button  className={classes.button} disableRipple>
        <Avatar alt="Smiley face" src={Account}/>
      </Button>
      <Typography>  
        <Link href="#" onClick={event => event.preventDefault()} color="inherit">
          Marko Marković 
        </Link>
      </Typography>
    </div>
  );
}

export default withRouter(Post);