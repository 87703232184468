import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Home from "../pages/home"
import About from "../pages/about"
import CreatePost from "../pages/createPost";
import Post from "../pages/post";
import Login from "../pages/login";
import Register from "../pages/register";
import Roles from "../pages/roles";
import Tags from "../pages/tags"
import EditPost from "../pages/editPost"
import Mails from "../pages/mails"

import * as routes from "./routes";

const Router = () => {
    
    return(
        <BrowserRouter>
            <Route exact path={routes.homeRoute} render={() => <Home /> }/> 
            <Route path={routes.aboutRoute} render={()=><About />} /> 
            <Route path={routes.createPostRoute} render={()=><CreatePost />}/> 
            <Route path={routes.postRoute} render={()=><Post />}/>
            <Route path={routes.loginRoute} render={()=><Login />}/>  
            <Route path={routes.registerRoute} render={()=><Register />}/> 
            <Route path={routes.rolesRoute} render={()=><Roles />}/> 
            <Route path={routes.tagsRoute} render={()=><Tags />}/> 
            <Route path={routes.editPostRoute} render={()=><EditPost />}/> 
            <Route path={routes.mailsRoute} render={()=><Mails />}/> 
            {/* <Route  path={orderDetailsRoute} render={(props)=><OrderDetails state={props.location.state}/>} />  */}
        </BrowserRouter>
    )
}

export default Router