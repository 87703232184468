import Router from "./router"
import React, { Fragment } from "react";
import EventListeners from "./config/eventListeners";
import LocalStorage from "./config/localStorage";

function App() {
  return (
    <Fragment>
      <EventListeners/>
      <LocalStorage/>
      <Router/>
    </Fragment>
  );
}

export default App;