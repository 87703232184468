const Service= (url, DTO) =>{
  return  fetch(url + '?' + new URLSearchParams(DTO))
          .then(resp => resp.blob())
          .then(response => {

            const file = new File([response], "imeFajla"); 
            //imeFajla is not importrnat, because it will be calculated from path 
            //and changed in //frontend\src\pages\post\components\post\documents\index.jsx
           
            return file
          })
}

export default Service;