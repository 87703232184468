import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
   
    controlls: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: 10,
        justifyContent: "flex-end",
    },

    like: {
        margin: "0px 0px -3px 5px"
    },

    tag: {
        marginRight: 5,
        marginBottom: 5,
    },

    date: {
        // marginLeft: 30,
    },

    backgroundColor: {
        backgroundColor: "black",
        // backgroundColor: "#2ec46d",
        color: "white",
        border: "1px solid black",
        '&:hover': {
            boxShadow: "0px 0.5px 1px 0px rgba(0,0,0,0.75)",   
            // backgroundColor: "#2ec46d !important",
            backgroundColor: "#000000d6 !important",
        },
        minWidth: 116,
    },

    fontType: {
        textTransform: 'none',
        color: "white",
    },

});

export default classStyles;
