import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import classStyles from './styles';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import API from "../../API/API"
import Dialog from "../../../../components/dialog";
import { homeRoute } from "../../../../router/routes";
import { withRouter } from 'react-router-dom';
import { SET_USER } from "../../../../store/user/actions";
import {useDispatch} from "react-redux"
import useIsMounted from "../../../../components/isMounted"

const Login = (props) => {
  const classes = classStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dialog, setDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContet, setDialogContent] = useState("");
  const [apiResult, setApiResult] = useState(null);
  const [button, disableButton] = useState(false);
  const dispatch = useDispatch()
  const isMounted = useIsMounted();


  const onClick = () => {

      disableButton(true)

      let DTO = {
        username: username, 
        password: password
      }
   
      API(DTO)
      .then(result => {
        if(isMounted())
          setApiResult(result)
      })
  }

  useEffect(() => {
    if(apiResult!=null){
      disableButton(false)
      const {status} = apiResult;

      if(status==="error"){
        const {data} = apiResult;
        const {code} = data;

        setDialogTitle("Desila se nepoznata greska")
        setDialogContent("Posaljite ovu gresku tehnickoj podrsci: "+code)
        setDialog(true);
      }

      if(status==="userExist"){
        const {token, role} = apiResult;
        dispatch({type: SET_USER, payload: {token, role}});
        props.history.push(homeRoute);
      }   

      if(status==="userDoesntExist"){
        setDialogTitle("Ne postoji takav korisnik")
        setDialogContent("Pokusajte ponovo.")
        setDialog(true);
      }  
    }

  }, [apiResult]);

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.heading} >Prijavljivanje</Typography>
      <Paper className={classes.paper} elevation={0}>
      <TextField
          placeholder="Korisničko ime"
          autoComplete="username"
          InputProps={{
            className: classes.input
          }}
          fullWidth
          variant="outlined"
          onChange={(e)=>setUsername(e.target.value)}
        />
        <TextField
        placeholder="Šifra"
        type="password"
        autoComplete="current-password"
        InputProps={{
          className: classes.input
        }}
        fullWidth
        variant="outlined"
        onChange={(e)=>setPassword(e.target.value)}
      />
      <Button disabled={button} fullWidth size="large" className={classes.button} onClick={onClick}>Prijavi me</Button>
      {/* <Link href="#" className={classes.passwordChange} color="inherit" underline="always">Ne sećаš se lozinke?</Link> */}
      </Paper>
      <Dialog  dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
    </div>
  );
}

export default withRouter(Login);
