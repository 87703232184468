
const initialState = {
    token: null,
    role: "posetioc",
    showAdminBar: false
};
  
const state = (() => {
  
  return localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : initialState
})()

export default state