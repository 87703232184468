import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import classStyles from './styles';
import { EDIT_POST_SET_TEXT } from "../../store/actions";
import {useDispatch, useSelector} from "react-redux"

const TextArea = () => {
    const classes = classStyles();
    const dispatch = useDispatch(); 
  
    const editPost = useSelector(state => state.editPost);
    const { text } = editPost;

    const onChange = (event) => {
        const value = event.target.value
        dispatch({type: EDIT_POST_SET_TEXT, payload: value});
    }

    return (
        text=="" ? 
        null 
        :
        <TextareaAutosize 
            minRows={10} 
            placeholder="" 
            className = {classes.textArea}
            onChange={onChange}
            defaultValue={text}
        />
    )
}

export default TextArea;