import React, { Fragment } from 'react';
import Category from "./components/category";
import Posts from "./components/posts";  
import CreatePost from "./components/createPost";
import Search from "./components/search";
import Spacer from "../../components/spacer";
import classStyles from './styles';
import Navbar from "../../components/navbar";
import Tags from "./components/tags";

const Home = () => {
    const classes = classStyles();

    return (
    <Fragment>
    <Navbar/>
    <div className={classes.rootContainer}>
       <Spacer/>
        <div className={classes.contentContainer}>
          <CreatePost/>
          <Search/>
          <Tags/>
          {/* <Filters/> */}
          {/* <Category/> */}
          <Posts/>
        </div>        
        <Spacer/>
    </div>        
    </Fragment>
  );
}

export default Home;