const initialState = {
    title: "",
    text: "",
    type: "",
    likes: 50,
    date: null,
    tags: [],
    images: [],
    documents: []
};

export default initialState