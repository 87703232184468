import React, { useState, useEffect } from 'react';
import classStyles, { styles } from './styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { homeRoute } from "../../../../router/routes";
import { ROLES_CLEAR_USER } from "../../store/actions";
import {useSelector, useDispatch} from "react-redux"
import API from "../../API/APIChangeRole"
import Dialog from "../../../../components/dialog";
import useIsMounted from "../../../../components/isMounted"

const Controlls= (props) => {
    const classes = classStyles();
    const roles = useSelector(state => state.roles);
    const { username, role } = roles;
    const dispatch = useDispatch(); 
    const [apiResult, setApiResult] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContet, setDialogContent] = useState("");
    const [button, disableButton] = useState(true);
    const isMounted = useIsMounted();

    const submit = () => {
      let DTO = {
        username: username, 
        role: role
      }

      API(DTO)
      .then(result => {
        if(isMounted())
          setApiResult(result)
      })
    }

    const cancel = () => {
      dispatch({type: ROLES_CLEAR_USER})
      props.history.push(homeRoute)
    }

    useEffect(() => {
      if(username===null){
        disableButton(true)
      }else{
        disableButton(false)
      }
  
    }, [username]);

    useEffect(() => {
      if(apiResult!=null){
        const {status} = apiResult;
        if(status=="sucess"){
          const {token, role} = apiResult;
          setDialogTitle("Uspesno ste promenili privilegije")
          setDialog(true);

          dispatch({type: ROLES_CLEAR_USER})
          props.history.push(homeRoute);
        }else{
          setDialogTitle("Desila se greska")
          setDialogContent("Pokusajte ponovo.")
          setDialog(true);
        }
      }
  
    }, [apiResult]);

    return (
      <div className={classes.container}>
      <Button disabled={button} onClick={submit} className={classes.submit} style={styles.buttonColor(button)}>
        <Typography variant="button" color="inherit" className={classes.fontStyle}>Potvrdi</Typography>
      </Button>
      <Button onClick={cancel} className={classes.cancel} >
        <Typography variant="button" color="inherit" className={classes.fontStyle}>Odustani</Typography>
      </Button>
      <Dialog  dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
      </div>
     
  );
}

export default withRouter(Controlls);