import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    appbar: {
        // backgroundColor: "#555c64",  
        boxShadow: "none",
        padding: "0px 0px !important", 
        height: 80,
    },

    toolbar: {
        // backgroundColor:"#25282ca6",
        backgroundColor:"#131415",
        padding: "0px 10px", 
        height: 80,
    },

    toolbar2: {
        height: 80,
    },
});

export default classStyles;
