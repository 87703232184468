import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({

    link: {
        color: "white",
        textDecoration: "none",
        display:"flex", 
    },

});

export default classStyles;
