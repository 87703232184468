import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    title: {
        marginBottom: 20,
    },

});

export default classStyles;
