import React from 'react';
import classStyles from './styles';

const BottomBar = () => {
  const classes = classStyles();

  return (
    <div className={classes.container}>
    
    </div>  
    );
}

export default BottomBar;