import { combineReducers } from "redux"
import screenReducer from "./screen/reducer"
import homeReducer from "../pages/home/store/reducer";
import createPostReducer from "../pages/createPost/store/reducer";
import userReducer from "./user/reducer";
import rolesReducer from "../pages/roles/store/reducer"
import tagsReducer from "../pages/tags/store/reducer"
import editPostReducer from "../pages/editPost/store/reducer"
import emailReducer from "../pages/mails/store/reducer"

const allReducers = combineReducers({
    screen: screenReducer,
    home: homeReducer,
    createPost: createPostReducer,
    user: userReducer,
    roles: rolesReducer,
    tags: tagsReducer,
    editPost: editPostReducer,
    editPost: editPostReducer,
    email: emailReducer,
})

export default allReducers   