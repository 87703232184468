import React, { useState, Fragment } from "react";
import FileReader from "../components/fileReader"
import PDFReader from "../components/pdfReader";
import classStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FileUploader = (props) => {
    const classes = classStyles();
    const { files } = props;
    const [ file, setFile ] = useState(null)
    const [ selectedFile, setSelectedFile ] = useState(-1);

    const fileExtension = (file) => {
        let filename;

        if(isString(file)){
            filename = file.split(".");
        }else{
            filename = file.name.split(".");
        }

        filename = filename[filename.length-1]
        
        return filename
    }

    const isString = (file) => {
        return typeof file === 'string' ? true : false
    }

    const fileName = (file) => {
        let filename;

        if(isString(file)){
            filename = file.split("/");
        }else{
            filename = file.name.split("/");
        }

        filename = filename[filename.length-1]
        
        return filename
    }

    const changeSelectedFile = (event) => {
        let index = event.target.value;

        setSelectedFile(index);
        
        if(index==-1)
            setFile(null)
        else
            setFile(files[index])
    };


    return (
        <Fragment>
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel >Odaberi fajl</InputLabel>
                <Select
                    value={selectedFile}
                    onChange={changeSelectedFile}
                >
                    <MenuItem value={-1}>
                        <em>Nijedan</em>
                    </MenuItem>
                    {files.map((file, index) => <MenuItem key={index} value={index}>{fileName(file)}</MenuItem>)}
                </Select>
            </FormControl>

            {file!=null && fileExtension(file)!="pdf" ? <FileReader file={file} isString={isString}/> : null}
            {file!=null  && fileExtension(file)=="pdf"? <PDFReader file={file} isString={isString}/> : null}
        </Fragment>
    )

}

export default FileUploader

