import * as actions from "./actions";
import initialState from "./state"

const Reducer = (state=initialState, action) => {
    switch (action.type) {
        case actions.CREATE_POST_SET_TITLE:
            return {
                ...state,
                title: action.payload
            };
        case actions.CREATE_POST_SET_TEXT:
            return {
                ...state,
                text: action.payload
            };
        case actions.CREATE_POST_SET_TYPE:
            return {
                ...state,
                type: action.payload
            };
        case actions.CREATE_POST_SET_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        case actions.CREATE_POST_SET_IMAGES:
            return {
                ...state,
                images: action.payload
            };
        case actions.CREATE_POST_SET_DOCUMENTS:
            return {
                ...state,
                documents: action.payload
            };    
        case actions.CREATE_POST_CLEAR_STORE:
            return {
                ...initialState
            };  
        default:
            return state;
    }
  };
  
  export default Reducer;