import React from 'react';
import classStyles from './styles';
import TextField from '@material-ui/core/TextField';
import {useDispatch, useSelector} from "react-redux"
import { EDIT_POST_SET_TITLE } from "../../store/actions";

const Title = () => {
    const classes = classStyles();
    const dispatch = useDispatch(); 

    const editPost = useSelector(state => state.editPost);
    const { title } = editPost;

    const onChange = (event) => {
        const value = event.target.value
        dispatch({type: EDIT_POST_SET_TITLE, payload: value});
    }

    
    return (
        title=="" ? 
        null 
        :
        <TextField label="Naslov" defaultValue={title} className={classes.title} fullWidth onChange={onChange}/>
  );
}

export default Title;