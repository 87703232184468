import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import classStyles from './styles';
import Button from "@material-ui/core/Button";
import { HOME_SET_SEARCH, HOME_RELOAD_POSTS } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux"

const Search = () => {
  const classes = classStyles();
  const [text, setText] = useState([])
  const dispatch = useDispatch(); 
  const home = useSelector(state => state.home);
  const { search } = home;

  const handleSearch = () => {
    dispatch({type: HOME_RELOAD_POSTS});
  }
  
  const onChange = (e) => {
    setText(e.target.value)
    dispatch({type: HOME_SET_SEARCH, payload: e.target.value});
  }

  return (
    <Paper component="form" className={classes.root}>
     <InputBase
        className={classes.input}
        placeholder="Pretraga"
        onChange={onChange}
        defaultValue={search}
      />
      <Button className={classes.searchButton}  onClick={handleSearch}><SearchIcon /></Button>
    </Paper>
  );
}

export default Search