import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
   
    photo: {
        border: "solid 1px",
        marginBottom: 20,
        marginRight: 5,
    },

});

export default classStyles;
