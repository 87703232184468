import React, { Fragment } from 'react';
import { Toolbar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import classStyles from './styles';
import Divider from '@material-ui/core/Divider';
import Mobile from "./mobile";
import Computer from "./computer";

export const Navbar = () => {
    const classes = classStyles();


        return(
            <Fragment>
                <AppBar position="fixed" className={classes.appbar}>
                <Toolbar className={classes.toolbar} variant="dense">
                    <Mobile/>
                    <Computer/>
                </Toolbar>
                </AppBar>
                <Toolbar variant="dense" className={classes.toolbar2}/>
                <Divider/>
            </Fragment>
        )
}

  export default Navbar;
