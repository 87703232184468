import React, { Fragment,useEffect } from 'react';
import Spacer from "../../components/spacer";
import Navbar from "../../components/navbar";
import classStyles from './styles';
import Title from "./components/title";
import Type from "./components/type";
import FormTitle from "./components/formTitle";
// import TextEditor from "./components/textEditor";
import Paper from '@material-ui/core/Paper';
import FileUploader from "./components/fileManager/fileUploader"
import Controlls from "./components/controlls";
import TextArea from "./components/textArea";
import ImageUploader from "./components/imageUploader"
import Tags from "./components/tags"
import {useDispatch} from "react-redux"
import {EDIT_POST_CLEAR_STORE} from "./store/actions"
import { withRouter } from 'react-router-dom';
import * as actions from "./store/actions";

const EditPost = (props) => {
    const classes = classStyles();
    const dispatch = useDispatch(); 

    const {data} = props.location.state;
    const { title, type, text, images, documents, tags, likes, id, userId } = data;

    useEffect(() => {
      dispatch({type: actions.EDIT_POST_SET_TITLE, payload: title});
      dispatch({type: actions.EDIT_POST_SET_TYPE, payload: type});
      dispatch({type: actions.EDIT_POST_SET_TEXT, payload: text});
      dispatch({type: actions.EDIT_POST_SET_IMAGES, payload: images});
      dispatch({type: actions.EDIT_POST_SET_DOCUMENTS, payload: documents});
      dispatch({type: actions.EDIT_POST_SET_TAGS, payload: tags});
    }, []);

    useEffect(() => {
      return () => {dispatch({type: EDIT_POST_CLEAR_STORE})}
    }, []);

    return (
    <Fragment>
    <Navbar/>
    <div className={classes.container}>
      <Spacer/>
      <div className={classes.contentContainer}>
        <FormTitle/>
        <Type/>
        <Paper className={classes.paper} elevation={0}>
          <Title/>
           {/* <TextEditor/> */}
          <TextArea/>
          <ImageUploader/>
          <FileUploader/>
          <Tags/>
          <Controlls id={id} userId={userId}/>
        </Paper>
      </div>        
      <Spacer/>
    </div>        
    </Fragment>
  );
}

export default withRouter(EditPost);