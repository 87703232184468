export const EDIT_POST_SET_TITLE = "EDIT_POST_SET_TITLE"

export const EDIT_POST_SET_TEXT = "EDIT_POST_SET_TEXT"

export const EDIT_POST_SET_TYPE = "EDIT_POST_SET_TYPE"

export const EDIT_POST_SET_TAGS = "EDIT_POST_SET_TAGS"

export const EDIT_POST_SET_IMAGES = "EDIT_POST_SET_IMAGES"

export const EDIT_POST_SET_DOCUMENTS = "EDIT_POST_SET_DOCUMENTS"

export const EDIT_POST_CLEAR_STORE = "EDIT_POST_CLEAR_STORE"
