/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classStyles from './styles';
import { ROLES_SET_USER, ROLES_CLEAR_USER } from "../../store/actions";
import {useSelector, useDispatch} from "react-redux"
import API from "../../API/APIGetUsers";
import useIsMounted from "../../../../components/isMounted"

const options = [];

export default function ComboBox() {
    const classes = classStyles();
    const dispatch = useDispatch(); 
    const [users, setUsers] = useState([]);
    const isMounted = useIsMounted();
    // const [inputValue, setInputValue] = React.useState('');
      
    useEffect(() => {
        API()
        .then(result => {
            if(isMounted())
                setUsers(result)
        })
    }, []);

    const onChange = (e, user) => {
        if(user==null){
            dispatch({type: ROLES_CLEAR_USER})
        }else{
            dispatch({type: ROLES_SET_USER, payload: {username: user.username, role: user.role}})
        }
    }

  return (
    <Autocomplete
    // value={value}
    onChange={onChange}
    options={users}
    // inputValue={inputValue}
    // onInputChange={(event, newInputValue) => {
    //     console.log(newInputValue)
    //     setInputValue(newInputValue);
    // }}
    getOptionLabel={(option) =>  option.length===0 ? "": option.username}
    style={{ width: 300 }}
    renderInput={(params) => <TextField {...params} label="Korisnicko ime" variant="outlined" />}
  />
  );
}

