import React, { Fragment, useEffect, useState } from 'react';
import Navbar from "../../components/navbar";
import classStyles from './styles';
import Spacer from "../../components/spacer";
import PostComponent from "./components/post";
// import Answer from "./components/answer";
// import CreateAnswer from "./components/createAnswer"
import APIGetPost from "./API/APIGetPost"
import { withRouter } from 'react-router-dom';
import useIsMounted from "../../components/isMounted"

const Post = (props) => {
  const classes = classStyles();
  const [post, setPost] = useState(null);
  const {postId} = props.location.state;
  const isMounted = useIsMounted();

  useEffect(() => { //inicijalno ucitavanje posta
    APIGetPost({postId: postId})
    .then(result => {
      if(isMounted()){
        setPost(result)
      }
    })
  }, []);

  return (
    <Fragment>
      <Navbar/>
      <div className={classes.container}>
        <Spacer/>
        <div className={classes.contentContainer}>
          {post!=null ? <PostComponent data={post}/> : null}
          {/* <Answer/> */}
          {/* <CreateAnswer/> */}
        </div>        
        <Spacer/>
      </div> 
    </Fragment>
    );
}

export default withRouter(Post);