import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    rootContainer: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 650px)" : {
            flexDirection: "column",
            alignItems: "center",
        },
    },

    extraButtons: {
        display: "flex",
    },

    container: {
        width: "40%",
    }
});

export default classStyles;
