export const CREATE_POST_SET_TITLE = "CREATE_POST_SET_TITLE"

export const CREATE_POST_SET_TEXT = "CREATE_POST_SET_TEXT"

export const CREATE_POST_SET_TYPE = "CREATE_POST_SET_TYPE"

export const CREATE_POST_SET_TAGS = "CREATE_POST_SET_TAGS"

export const CREATE_POST_SET_IMAGES = "CREATE_POST_SET_IMAGES"

export const CREATE_POST_SET_DOCUMENTS = "CREATE_POST_SET_DOCUMENTS"

export const CREATE_POST_CLEAR_STORE = "CREATE_POST_CLEAR_STORE"
