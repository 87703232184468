import {useSelector, useDispatch} from "react-redux"
import { useEffect } from "react";
import { SCREEN_WIDTH, SCREEN_HEIGHT} from "../store/screen/actions";

const Listener = () => {

  const screen = useSelector(state => state.screen);
  const dispatch = useDispatch(); 

  const handleResize = () => {
    dispatch({type: SCREEN_WIDTH, payload: window.innerWidth});
    dispatch({type: SCREEN_HEIGHT, payload: window.innerHeight});
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []);


  return (
    null
  );
}

export default Listener;
