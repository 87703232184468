import React from 'react';
import classStyles from './styles';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { CLEAR_USER } from "../../../../store/user/actions";
import { withRouter } from 'react-router-dom';
import {useDispatch} from "react-redux"
import { homeRoute } from "../../../../router/routes";
import { HOME_RELOAD_POSTS } from "../../../../pages/home/store/actions";

export const Logout = (props) => {
  const classes = classStyles();
  const dispatch = useDispatch(); 

  const handleClick = () => {
    dispatch({type: CLEAR_USER});
    dispatch({type: HOME_RELOAD_POSTS});
    const path = props.location.pathname

    if(path!==homeRoute)
        props.history.push(homeRoute);
  }

  return(
    <Button className={classes.color} onClick={handleClick}>
      <Typography variant="subtitle2">
        Odjavljivanje
      </Typography>
    </Button>
  )
}

export default withRouter(Logout);
