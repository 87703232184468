import React, {useState, useEffect} from 'react';
import classStyles from './styles';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { homeRoute } from "../../../../router/routes";
import useIsMounted from "../../../../components/isMounted"
import {useSelector} from "react-redux"
import Dialog from "../../../../components/dialog"
import APIAddPost from "../../API/APIAddPost"
import APIDeletePost from "../../API/APIDeletePost"
import APIUploadFile from "../../API/APIUploadFile"
import {currentDate} from "../../../../components/date"

const Controlls= (props) => {
    const classes = classStyles();
    const isMounted = useIsMounted();
    const createPost = useSelector(state => state.createPost);
    const user = useSelector(state => state.user);
    const { token } = user;
    const { title, text, type, likes, date, tags, images, documents } = createPost;
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContet, setDialogContent] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [postCreated, setPostCreated] = useState(false);
    
    useEffect(()=> {
      if(postCreated)
        props.history.push(homeRoute)
      
    }, [postCreated])

    const onSubmit = () => {
      setButtonDisabled(true)
      if(title!="" && text!=""){
        let DTO = {
          title: title,
          text: text,
          type: type,
          likes: likes,
          date: currentDate,
          tags: tags,
          token: token
        }

        // let DTO = {
        //   title: "Ovo je naslov.",
        //   text: "Ovo je sadrzaj",
        //   type: "clan",
        //   likes: 50,
        //   date: currentDate,
        //   tags: [{id: 8, tag: "darjan"}, {id: 14, tag: "sada"}]
        // }

        APIAddPost(DTO)
        .then(result =>{
              const {postId} = result;

              try{
                for (const image of images) {
                  let formData = new FormData();
                  formData.append("file", image)
                  formData.append("postId", postId);
                  APIUploadFile(formData)
                }

                for (const document of documents) {
                  let formData = new FormData();
                  formData.append("file", document)
                  formData.append("postId", postId);
                  APIUploadFile(formData)
                }

                if(isMounted()){
                  // setDialogTitle("Uspeno ste dodali objavu")
                  // setDialogContent("")
                  // setDialog(true);
                  setPostCreated(true)
                }
              }
              catch(err){
                console.log(err)
                // APIDeletePost(postId) //objava nije uspeno dodata, pa da se obrise sve sto ima veze sa njom
              }
        })

      }
      else if(title==""){
        setDialogTitle("Popunite sve podatke")
        setDialogContent("Niste uneli naslov.")
        setDialog(true);
      }
      else{
        setDialogTitle("Popunite sve podatke")
        setDialogContent("Niste uneli sadrzaj.")
        setDialog(true);
      }

      setButtonDisabled(false)
    }

    return (
      <div className={classes.container}>
      <Button onClick={onSubmit} className={classes.submit} disabled={buttonDisabled}>
        <Typography variant="button" color="inherit" className={classes.fontStyle}>Potvrdi</Typography>
      </Button>

      <Button onClick={() => props.history.push(homeRoute)} className={classes.cancel} disabled={buttonDisabled}>
        <Typography variant="button" color="inherit" className={classes.fontStyle}>Odustani</Typography>
      </Button>
      <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
      </div>
     
  );
}

export default withRouter(Controlls);