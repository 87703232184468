
export const initialState = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
};
  
const state = (() => (
  localStorage.getItem("screen") !== null ? 
    JSON.parse(localStorage.getItem("screen")) 
    : 
    initialState
))()

export default state