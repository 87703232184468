import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    text: {
        marginTop: 50,
        marginBottom: 30,
    },
    

});

export default classStyles;
