/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classStyles from './styles';
import {useDispatch, useSelector} from "react-redux"
import { Fragment } from 'react';
import { MAILS_SET_EMAIL, MAILS_RELOAD_EMAILS } from "../../store/actions";
import useIsMounted from "../../../../components/isMounted"
import APIGetEmails from "../../API/APIGetEmails";

export default function ComboBox() {
  const classes = classStyles();
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({id:-1, address: ""});
  const isMounted = useIsMounted();
  const dispatch = useDispatch(); 

  const emailStore = useSelector(state => state.email);
  const { reloadEmails } = emailStore;

  useEffect(() => { 
      APIGetEmails()
      .then(result => {
        if(isMounted())
          setEmails(result)
      })
  }, []);

  useEffect(() => { 
    if(reloadEmails){
      APIGetEmails()
      .then(result => {
        if(isMounted())
          setEmails(result)
      })

      setEmail({id:-1, address: ""})
      dispatch({type: MAILS_RELOAD_EMAILS, payload: false});
      dispatch({type: MAILS_SET_EMAIL, payload: {id:-1, address: ""}});
    }
}, [reloadEmails]);

  const onInputChange = (e, value, reason) => {
    setEmail({id:-1, address: value})
  }

  const onBlur = () => {
      let mail = emails.find(el => el.address===email.address)
      if(mail==undefined)
        mail = email;

      dispatch({type: MAILS_SET_EMAIL, payload: mail});
  }

  return (
    <Fragment>
        <Autocomplete
        onInputChange={onInputChange}
        value={email}
        onBlur={onBlur}
        options={emails}
        getOptionLabel={(option) => option==="" ? "": option.address}
        renderInput={(params) => <TextField {...params} label="Email adresa" variant="outlined" />}
        />
    </Fragment>
  );
}

