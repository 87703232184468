import React, { Fragment } from 'react';
import Navbar from "../../components/navbar";
import Spacer from "../../components/spacer"
import Motivation from "./components/motivation";
import classStyles from './styles';
import RegisterForm from "./components/register";
import Google from "./components/google";
import Outlook from "./components/outlook";
import Logo from "./components/logo";
import BottomBar from "./components/bottomBar";

const Register = () => {
  const classes = classStyles();

  return (
    <Fragment>
     
      <Navbar/>
      <div className={classes.rootContainer}>
        <Spacer/>
        {/* <Logo/> */}
        <div className={classes.contentContainer}>
          {/* <Motivation/> */}
          <RegisterForm/>
          {/* <div className={classes.extraButtons}>
            <Google/>
            <Outlook/>
          </div> */}
        </div>        
        <Spacer/>
      </div>  
      <BottomBar/>
    </Fragment>
    );
}

export default Register;