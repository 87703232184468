import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
   
    text: {
        marginBottom: 20,
    }

});

export default classStyles;
