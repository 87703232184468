import * as actions from "./actions";
import initialState from "./state"; 
 
const Reducer = (state=initialState, action) => {
    switch (action.type) {
        case actions.SET_USER:
            return {
                ...state,
                token: action.payload.token,
                role: action.payload.role

            };
        case actions.CLEAR_USER:
            return {
                ...state,
                token: null,
                role: "posetioc"
                // role: nullz
            };    
        default:
            return state;
    }
};

export default Reducer;