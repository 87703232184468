import Service from "../../../../services/post";
import { changeRole as url} from '../endpoints';

const Provider = async (DTO) => {
    try {
        const resultList = await Service(url, DTO)
        return resultList
    } catch (err) {
        throw new Error('Go boom')
    }
}

export default Provider;