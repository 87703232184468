import React, {useEffect, useState} from 'react';
import classStyles from './styles';
import TextField from '@material-ui/core/TextField';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import API from "../../API/APIAddTag";
import Dialog from "../../../../components/dialog";
import { TAGS_UPDATE_TAGS } from "../../store/actions";
import { useDispatch } from "react-redux"
import useIsMounted from "../../../../components/isMounted"

export default function SimpleSelect() {
    const classes = classStyles();
    const [tag, setTag] = React.useState("");
    const [dialog, setDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContet, setDialogContent] = useState("");
    const [button, disableButton] = useState(false);
    const [apiResult, setApiResult] = useState(null);
    const dispatch = useDispatch(); 
    const isMounted = useIsMounted();


    const handleChange = (event) => {
        setTag(event.target.value);
    };
  
    const submit = () => {
        disableButton(true)

        let DTO = {
          tag: tag
        }
  
        API(DTO)
        .then(result => {
          if(isMounted())
            setApiResult(result)
        })

        setTag("")
    }

    useEffect(() => {
        if(apiResult!=null){
          const {status} = apiResult;

          if(status=="error"){
            const {dbResponse} = apiResult;
            const {code} = dbResponse;
    
            if(code=="ER_DUP_ENTRY"){
              setDialogTitle("Tag ime vec postoji")
              setDialogContent("Pokusajte ponovo.")
              setDialog(true);
            }
          }

          if(status=="sucess"){
            const {token, role} = apiResult;
            setDialogTitle("Uspesno ste dodali tag")
            setDialogContent("Unesite novi tag.")
            setDialog(true);
            dispatch({type: TAGS_UPDATE_TAGS, payload: true});
          }

          disableButton(false)
        }
    
      }, [apiResult]);

    return (
        <Fragment>
            <TextField value={tag} onChange={handleChange} label="Unesite tag" variant="outlined"/>
            <Button disabled={button} onClick={submit} className={classes.submit}>
                <Typography variant="button" color="inherit" className={classes.fontStyle}>Dodaj tag</Typography>
            </Button>
            <Dialog dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
        </Fragment>
    );
}
