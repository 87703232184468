import React, {useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import classStyles from './styles';
import APIGetTags from "../../API/APIGetTags";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useIsMounted from "../../../../components/isMounted"
import { EDIT_POST_SET_TAGS } from "../../store/actions";
import {useDispatch, useSelector} from "react-redux"

const ChipsArray = () => {
  const classes = classStyles();
  const [tag, setTag] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [chosenTags, setChosenTags] = useState([]);
  const isMounted = useIsMounted();
  const dispatch = useDispatch(); 

  const editPost = useSelector(state => state.editPost);
  const { tags } = editPost;

  useEffect(()=> {
    if(tags.length>0){
      setChosenTags(tags);
    }
  },[tags])


  const onDelete = (value) => {
    setChosenTags(chosenTags.filter(item => item!=value));
    setTag(null)
  }

  const onChange = (e, value) => {
    if(value!==null){
      // let exist = chosenTags.includes(value)
      let exist = chosenTags.some(el => el.id===value.id)
      if(!exist){
        setChosenTags(chosenTags => [...chosenTags, value])
      }
      setTag(value)
    }else{
      setChosenTags(chosenTags.filter(item => item!=tag));
      setTag(value)
    }
  }

  useEffect(() => { 
    dispatch({type: EDIT_POST_SET_TAGS, payload: chosenTags});
  }, [chosenTags]);

  useEffect(() => { //inicijalno ucitavanje tagova
      APIGetTags()
      .then(result => {
        if(isMounted()){
          setAllTags(result)
        }
      })
  }, []);

  return (
    <div className={classes.container}>
        <Autocomplete
        value={tag}
        onChange={onChange}
        options={allTags}
        getOptionLabel={(option) =>  option==="" ? "": option.tag}
        style={{ width: 180}}
        renderInput={(params) => <TextField {...params} label="Dodaj tag" size="small"  variant="outlined" />}
        />
      {chosenTags.map(
        tag => <Chip key={tag.id} label={tag.tag} onDelete={()=>onDelete(tag)} className={classes.chip} classes={{deleteIcon: classes.deleteIconColor}}/>
      )}
    </div>
  );
}
export default ChipsArray;
