import React, {useEffect, useState} from 'react';
import classStyles from './styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { EDIT_POST_SET_TYPE } from "../../store/actions";
import {useDispatch, useSelector} from "react-redux"

const Type = () => {
  const classes = classStyles();

  const editPost = useSelector(state => state.editPost);
  const { type } = editPost;

  const [role, setRole] = useState("");
  const dispatch = useDispatch(); 

  useEffect(()=> {
    if(type!=""){
      setRole(type);
    }
  },[type])


  const handleChange = event => {
      const value = event.target.value
      setRole(value);
  };
    
  useEffect(()=> {
      dispatch({type: EDIT_POST_SET_TYPE, payload: role});
  },[role])

    return (
        <FormControl className={classes.formControl}>
          <InputLabel>Tip</InputLabel>
          <Select
            value={role}
            onChange={handleChange}
          >
            {/* <MenuItem value={10}>Pitanje</MenuItem>
            <MenuItem value={20}>Materijal</MenuItem> */}
            <MenuItem value={"posetioc"}>Posetilac</MenuItem>
            <MenuItem value={"clan"}>Clan</MenuItem>
          </Select>
      </FormControl>
  );
}

export default Type;