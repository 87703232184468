import Service from "../../../../services/get";
import { getUsers as url} from '../endpoints';

const Provider = async () => {
    try {
        const resultList = await Service(url)
        return resultList
    } catch (err) {
        throw new Error('Go boom')
    }
}

export default Provider;