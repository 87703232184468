import { makeStyles } from '@material-ui/styles';

const classStyles = makeStyles({
    container: {
        backgroundColor:"#131415",
        height: 50,
    }
});

export default classStyles;
