import React, { useEffect } from "react";
import {useSelector} from "react-redux"

const LocalStorage = () => {
  const state = useSelector(state => state);
  const {screen, home, user} = state;

  const updateHandler = true;

  useEffect(() => { 
    updateHandler ? localStorage.setItem("screen", JSON.stringify(screen)) : localStorage.clear() 
  }, [screen])

  useEffect(() => { 
    updateHandler ? localStorage.setItem("home", JSON.stringify(home)) : localStorage.clear() 
  }, [home])

  useEffect(() => { 
    updateHandler ? localStorage.setItem("user", JSON.stringify(user)) : localStorage.clear() 
  }, [user])

  return (
      null
  );
}

export default LocalStorage;
