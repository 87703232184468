import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import classStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { createPostRoute } from "../../../../router/routes";
import ArrowForward from '@material-ui/icons/ArrowForward';
import Dialog from "../dialog";
import {useSelector, useDispatch} from "react-redux"

const Controll = (props) => {
  const classes = classStyles();
  const user = useSelector(state => state.user);
  const { token } = user;

  const [dialog, setDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContet, setDialogContent] = useState("");

  const onClick = () => {
    if(token===null){
      setDialogTitle("Niste se prijavili")
      setDialogContent("Da bi ste kreirali objavu prvo se prijavite.")
      setDialog(true);
    }else{
      props.history.push(createPostRoute)
    }
  }

  return (
    <Fragment>
      <div className={classes.container}>
        <Typography variant="h6" color="inherit" className={classes.fontType}>Postavi materijal na oglasnu tablu</Typography>
        <ArrowForward/>
        <Button
          onClick={onClick}
          className={classes.button}
        >
          <Typography variant="button" color="inherit" className={classes.fontStyle}>Kreiraj objavu</Typography>
        </Button>

      </div>
      <Divider className={classes.divider}/>
      <Dialog  dialog={dialog} setDialog={setDialog} dialogTitle={dialogTitle} dialogContet={dialogContet}/>
    </Fragment>
    );
}

export default withRouter(Controll);