import React from 'react';
import Paper from '@material-ui/core/Paper';
import classStyles from './styles';
import Title from "./title";
import Details from "./details";
import Tags from "./tags";
import Documents from "./documents";
import Images from "./images"
import Text from "./text"
import Divider from '@material-ui/core/Divider';

const Post = (props) => {
  const classes = classStyles();
  const { data } = props;
  const { title, type, text, images, documents, tags, likes, id, userId, date } = data;
  console.log(data)
  return (
    <Paper className={classes.paper} elevation={0}>
      <Title title={title} type={type}/>
      <Text text={text}/>
      <Images images={images} />
      <Documents documents={documents}/>
      <Divider className={classes.devider}/>
      <Tags tags={tags}/>
      <Details likes={likes} postId={id} userId={userId} data={data} date={date}/>
    </Paper>
  );
}

export default Post;