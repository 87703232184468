import React, {useState, useEffect, Fragment} from 'react';
import classStyles from './styles';
import Post from "./post";
import APIGetPosts from "../../API/APIGetPosts"
import useIsMounted from "../../../../components/isMounted"
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import {useSelector, useDispatch} from "react-redux"
import { HOME_RELOAD_POSTS } from "../../store/actions";
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper';
import Fade from '@mui/material/Fade';

const Posts = () => {
  const classes = classStyles();
  const [posts, setPosts] = useState(null);
  const [postsToShow, setPostsToShow] = useState([]);
  const isMounted = useIsMounted();

  const user = useSelector(state => state.user);
  const { role } = user;

  const home = useSelector(state => state.home);
  const { reloadPosts, tags, search } = home;
  const dispatch = useDispatch(); 

  const [paginationRange, setPaginationRange] = useState(1);
  const [paginationCurrentPage, setpaginationCurrentPage] = useState(1);

  const defaultPostsPerPage = 1;
  const [postsPerPage, setPostsPerPage] = useState(defaultPostsPerPage);
  const [textFieldValue, setTextFieldValue] = useState(defaultPostsPerPage);
 

  useEffect(() => { //inicijalno ucitavanje postova
    let DTO = {
      type: role,
      tags: tags.map(tag => tag.id),
      search: search
    }

    APIGetPosts(DTO)
    .then(result => {
        if(isMounted()){
          setPosts(result)
        }
    })
  }, []);

  useEffect(() => { //ucitavanje postova ako se na stranici pritisne dugme za search 
    if(reloadPosts==true){
      let DTO = {
        type: role,
        tags: tags.map(tag => tag.id),
        search: search
      }
      // console.log(DTO)
      APIGetPosts(DTO)
      .then(result => {
          if(isMounted()){
            setPosts(result)
          }
      })

      dispatch({type: HOME_RELOAD_POSTS});
    }
  }, [reloadPosts]);
  
  useEffect(() => { 
      if(posts!=null){
        if(posts.length<=5){
          setTextFieldValue(posts.length)
          setPostsPerPage(posts.length)
        }else{
          setTextFieldValue(5)
          setPostsPerPage(5)
        }
        

        showPosts()
      }
  }, [posts]); 

  useEffect(() => { 
    if(posts!=null){
      showPosts();
    }
  }, [paginationRange]); 

  const updatePagination = () => {
    const decimal = (posts.length/postsPerPage)%1; //ako je decimalni broj, nema dovoljno postova da se popuni poslednja strana
    const numOfPages = decimal != 0 ? Math.floor(posts.length/postsPerPage)+1 : Math.floor(posts.length/postsPerPage)
    setPaginationRange(numOfPages);
    return numOfPages
  } 

  const showPosts = () => {
    const paginationRange = updatePagination()

    setPostsToShow([])
    let postsOnPage = parseInt(postsPerPage)

    if(paginationCurrentPage < paginationRange){//not on last page
      let startPost = (paginationCurrentPage-1)*postsOnPage;
      let endPost = startPost+postsOnPage

      // console.log("")
      // console.log("paginationCurrentPage " + paginationCurrentPage)
      // console.log("paginationRange " + paginationRange)
      // console.log("startPost "+startPost)
      // console.log("endPost "+endPost)
      // console.log("postsOnPage "+postsOnPage)
      // console.log("")

      for(let i=startPost; i<endPost; i++){
        setPostsToShow(oldArray =>[...oldArray, posts[i]])
      }
    }
    else{//on last page 
      let startPost = (paginationCurrentPage-1)*postsOnPage;
      for(let i=startPost; i<posts.length; i++){
        setPostsToShow(oldArray =>[...oldArray, posts[i]])
      }
    } 
  }

  const onBlur = (e, value) => {
    let startPost = (paginationCurrentPage-1)*postsPerPage;
    let postsPerPageNew = e.target.value 

    let paginationValue = Math.floor(startPost/postsPerPageNew+1)

    setpaginationCurrentPage(paginationValue)
    setPostsPerPage(postsPerPageNew)

    // console.log("")
    // console.log("postsPerPageNew " + (postsPerPageNew))
    // console.log("startPost " + startPost)
    // console.log("paginationCurrentPage " + (paginationValue))
    // console.log("")
  }

  const onTextFieldChange = (e) => {
    let postsPerPage = e.target.value 
    if(postsPerPage>0 && postsPerPage<=posts.length){
      setTextFieldValue(e.target.value)
    }
  }

  useEffect(() => { 
    if(posts!=null){
      showPosts();
    }
  }, [paginationCurrentPage, postsPerPage]); 


  return (
    <div className={classes.container}>
      
      { 
        posts==null ? 
          null 
          :
          posts.length>0 ? 
            <Fragment>    
              {postsToShow.map(data => <Post key={data.id} data={data}/>)}
              <div className={classes.paginationContainer}>
                <Pagination 
                  count={paginationRange} 
                  page={paginationCurrentPage} 
                  onChange={(event, paginationCurrentPage)  => setpaginationCurrentPage(paginationCurrentPage)}
                />
                <TextField 
                  value={textFieldValue} 
                  onChange={onTextFieldChange} 
                  label="Broj objava na strani" 
                  type={"number"} 
                  onBlur={onBlur} 
                  size="small" 
                  className={classes.textField}
                />
             </div>
            </Fragment>
            : 
            <Fade in={true}>
              <Paper elevation={4} className={classes.paper}>
                <Typography variant="h5"> 
                  {"Sadržaj još uvek nije kreiran!"}
                </Typography>
              </Paper>
            </Fade>
      }
    </div>
  );
}

export default Posts;
